/* @noflow */
import React from 'react';
import PropTypes from 'prop-types';
import {css} from '@emotion/core';

const buttonStyles = css`
    width: 70px;
    height: 70px;
    margin: 0;
    padding: 0;
    margin: 0 10px;
    display: inline-block;
    background-color: #fff;
    border: 0.5px solid #ccc;
    border-radius: 4px;
    text-align: center;
    font-size: 8px;
    cursor: pointer;
    :focus {
        box-shadow: 0 0 10px #719ece;
        border: 0.5px solid #ccc;
        outline: none;
    }
    :hover {
        background: #d8d8d8;
    }
`;
const disabled = css`
    pointer-events: none;
    background-color: #f4f5fb;
    color: #ced1dd;
`;
const IconButton = ({img, link, onClick, isDisabled, ariaLabel, overrideStyles}) => {
    return (
        <a
            css={
                isDisabled
                    ? css`
                          ${buttonStyles};
                          ${disabled};
                          ${overrideStyles};
                      `
                    : css`
                          ${buttonStyles};
                          ${overrideStyles};
                      `
            }
            href={link}
            aria-label={ariaLabel}
            rel="noopener noreferrer"
            className="icon-link"
            disabled={isDisabled}
            onClick={onClick}
        >
            {img}
        </a>
    );
};

IconButton.propTypes = {
    isDisabled: PropTypes.bool,
    link: PropTypes.string,
    onClick: PropTypes.func,
    img: PropTypes.element,
    ariaLabel: PropTypes.string,
    overrideStyles: PropTypes.string
};

IconButton.defaultProps = {
    isDisabled: false,
    link: `https://www.mapquest.com`,
    onClick: () => {},
    img: {},
    ariaLabel: ``,
    overrideStyles: ``
};

export default IconButton;
