/* @noflow */
import React from 'react';
import PropTypes from 'prop-types';
import {css} from '@emotion/core';

const ModalBottom = ({children, maxWidth, marginTop}) => {
    return (
        <aside
            className="modal-points"
            css={css`
                position: relative;
                width: 100%;
                height: 100%;
                text-align: center;
                padding: 3rem;

                @media (min-width: 668px) and (max-width: 800px) {
                    padding: 0rem;
                }
                @media (max-width: 700px) {
                    padding-bottom: 0rem;
                    overflow-y: scroll;
                    padding: 1rem 1.4rem 1rem;
                }
                @media (max-height: 700px) {
                    display: none;
                }
            `}
        >
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 1rem 3rem 1rem;
                    background: #fff;
                    max-width: ${maxWidth}px;
                    width: 100%;
                    border-radius: 8px;
                    margin: ${marginTop} auto 0;
                    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.11);

                    @media only screen and (max-width: 600px) {
                        margin: -7% auto;
                        padding: 1rem;
                    }
                    @media (min-width: 669px) and (max-width: 800px) {
                        margin: -25px auto;
                    }
                    @media (min-height: 669px) and (max-height: 719px) {
                        margin: -25% auto;
                    }
                    @media (min-height: 669px) and (max-height: 725px) and (min-width: 1000px) {
                        margin: -13% auto;
                    }
                    @media (min-height: 726px) and (max-height: 800px) {
                        margin: -33% auto;
                        padding: 0.6rem 3rem 1rem;
                    }
                    @media (min-height: 720px) and (max-height: 800px) and (min-width: 1000px) {
                        margin: -19% auto;
                        padding: 0.6rem 3rem 1rem;
                    }
                `}
            >
                {children}
            </div>
        </aside>
    );
};

ModalBottom.propTypes = {
    children: PropTypes.node.isRequired,
    maxWidth: PropTypes.string,
    marginTop: PropTypes.string
};

ModalBottom.defaultProps = {
    maxWidth: '650',
    marginTop: '50'
};

export default ModalBottom;
