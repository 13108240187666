/* @noflow */
/* eslint-disable react/jsx-filename-extension */
import React, {FC} from 'react';
import {css} from '@emotion/core';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

import {chromeExtensionLink} from '../../../constants';
// @ts-expect-error
import Modal from '../../Modal';
// @ts-expect-error
import ModalBottom from '../../ModalBottom';
import Button from '../../Button';
// @ts-expect-error
import IconButton from '../../IconButton';
// @ts-expect-error
import FooterDark from '../../FooterDark';
import MapquestLogo from '../../../assets/svg/mapquest-logo-black.svg';
import HotelsImg from '../../../assets/svg/hotels-off.svg';
import FoodImg from '../../../assets/svg/food-off.svg';
import ShoppingImg from '../../../assets/svg/shopping-off.svg';
import HospitalImg from '../../../assets/svg/hospital-off.svg';
import GroceryImg from '../../../assets/svg/grocery-off.svg';
import GasImg from '../../../assets/svg/gas-off.svg';
// @ts-expect-error
import TermsOfService from '../../TermsOfService';
// @ts-expect-error
import currentBrowserName from '../../../util/detectBrowser';
import {ExperimentProps} from '../type';

const LP2: FC<ExperimentProps> = ({onCtaClick}) => {
    const site = useStaticQuery(graphql`
        query LP2 {
            map: file(relativePath: {eq: "mapLosAngeles.png"}) {
                childImageSharp {
                    fixed(width: 1200, quality: 30) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `);

    return (
        <>
            <Img
                fixed={site.map.childImageSharp.fixed}
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    zIndex: '0'
                }}
                imgStyle={{
                    zIndex: '0',
                    position: 'relative'
                }}
            />

            <Modal marginTop="0px" maxWidth="650px" innerMargin="0px" height="64%" mobileHeight="71%">
                <div
                    css={css`
                        margin: 1rem 0 1rem;
                        @media (max-width: 668px) {
                            margin: 0;
                        }
                    `}
                >
                    <MapquestLogo />
                </div>
                <h1
                    css={css`
                        font-size: 1.7rem;
                        font-weight: 900;
                        color: #000;
                        line-height: 2.75rem;
                        letter-spacing: 0.05rem;

                        @media (min width: 601) and (max-width: 800px) {
                            font-size: 1.5rem;
                            line-height: normal;
                        }

                        @media (max-width: 600px) {
                            font-size: 1rem;
                        }
                        @media only screen and (max-width: 600px) {
                            line-height: 1rem;
                        }
                    `}
                >
                    Quick Driving Directions by MapQuest™
                </h1>
                <div
                    css={css`
                        margin: 1rem auto 1.5rem auto;
                        @media (max-height: 800px) {
                            margin: 0.5rem -50px;
                        }

                        @media (max-width: 660px) {
                            font-size: 1rem;
                            margin: 0;
                        }
                    `}
                >
                    {' '}
                    <IconButton img={<HotelsImg />} link={chromeExtensionLink} onClick={onCtaClick} />
                    <IconButton img={<FoodImg />} link={chromeExtensionLink} onClick={onCtaClick} />
                    <IconButton img={<ShoppingImg />} link={chromeExtensionLink} onClick={onCtaClick} />
                    <IconButton img={<HospitalImg />} link={chromeExtensionLink} onClick={onCtaClick} />
                    <IconButton img={<GroceryImg />} link={chromeExtensionLink} onClick={onCtaClick} />
                    <IconButton img={<GasImg />} link={chromeExtensionLink} onClick={onCtaClick} />
                </div>
                <div
                    className="instructions"
                    css={css`
                        font-family: 'Helvetica', 'Arial', sans-serif;
                        font-size: 1.2rem;
                        font-weight: 700;
                        margin: 0 0 15px 0;
                        @media (max-height: 900px) {
                            font-size: 1rem;
                        }
                        @media (max-width: 800px) {
                            font-size: 0.8rem;
                        }
                    `}
                >
                    <p>Step 1: Click the “Continue” Button</p>
                    <p>Step 2: Add the {currentBrowserName()}™</p>
                    <p>Step 3: Find your way with MapQuest!</p>
                </div>
                <Button
                    text="CONTINUE"
                    ariaLabel="CONTINUE"
                    link={chromeExtensionLink}
                    onClick={onCtaClick}
                    overrideButtonStyles={`
                        @media (max-height: 900px) {
                            padding: 1rem 5rem;
                        }
                        span.continue {
                            color:rgb(51, 51, 51);
                        }
                    `}
                />
                <TermsOfService
                    textStyles={`
                        font-size: 0.7rem;
                        a {
                            text-decoration: underline;
                        }`}
                />
            </Modal>
            <ModalBottom marginTop="-15px">
                <aside
                    css={css`
                        font-size: 0.9rem;
                        @media (max-height: 800px) {
                            font-size: 0.8rem;
                        }
                        @media (max-width: 668px) {
                            font-size: 0.7rem;
                        }
                    `}
                >
                    <p
                        css={css`
                            margin: 0 0 10px 0;
                            @media (max-height: 900px) {
                                margin: 0 0 5px 0;
                            }
                        `}
                    >
                        <span
                            css={css`
                                font-weight: bold;
                            `}
                        >
                            Get From Point A to Point B Faster!
                        </span>
                        <br />
                        <span>
                            The free MapQuest extension will ensure you find the quickest and best route to your
                            destination, along with live traffic and routing information to make your journey smooth and
                            problem free.
                        </span>
                    </p>
                    <p>
                        <span
                            css={css`
                                font-weight: bold;
                            `}
                        >
                            Print On The Go!
                        </span>
                        <br />
                        <span>
                            Wether you’re looking at a map you’ve manually zoomed into, a search result, or driving
                            directions, just click the print button within your browser to print out your desired map!
                        </span>
                    </p>
                </aside>
            </ModalBottom>
            <FooterDark />
        </>
    );
};

export default LP2;
